import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./loginForm.css";
import http from "../../http/index.tsx";
import utils from "../../utils/utils.tsx";
import AlertComponent from "../alertComponent/index.tsx";

const LoginForm = () => {
  const [seePassword, setSeePassword] = useState(false);
  const [passwordType, setPasswordType] = useState("");
  const [alertText, setAlertText] = useState("");
  const [severity, setSeverity] = useState("");

  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [erro, setErro] = useState("");

  const submitForm = (evento: React.FormEvent<HTMLFormElement>) => {
    evento.preventDefault();
    const usuario = {
      email,
      senha,
    };

    http
      .post("user/login", usuario)
      .then((resposta) => {
        console.log(resposta);
        sessionStorage.setItem("token", resposta.data.access_token);
        setEmail("");
        setSenha("");
        const token = resposta.data.access_token;
        const [header, payload, signature] = token.split(".");
        const userData = JSON.parse(atob(payload));

        const isAdmin = utils.isAdminEmail(userData.email);
        if (isAdmin) {
          navigate("/admin/home");
        } else {
          navigate("/user/home");
        }
      })
      .catch((erro) => {
        if (erro?.response?.data?.message) {
          // alert(erro.response.data.message)
          setAlertText(erro.response.data.message);
          setSeverity("error");
        } else {
          alert("Aconteceu um erro inesparado ao efetuar o seu login");
        }
      });
  };

  let navigate = useNavigate();

  useEffect(() => {
    if (seePassword) {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  }, [seePassword]);

  return (
    <section className="wrapper-login">
      <div className="form-wrapper">
        <div className="form-side">
          <a href="/" title="Logo">
            <img src={logo} alt="Logo da Enjoy" className="logo-enjoy" />
          </a>
          <form className="login" onSubmit={submitForm}>
            <h1 className="welcome-phrase">Bem-vinda de volta! &#128079;</h1>
            <h2 className="login-phrase">
              Acesse com a sua conta de fornecedora
            </h2>
            <div className="login-component">
              <span>E-mail</span>
              <input
                className="email-input-login"
                type="email"
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <div className="login-component">
              <span>Senha</span>
              <div className="senha-login">
                <input
                  className="senha-input-login"
                  type={passwordType}
                  onChange={(event) => setSenha(event.target.value)}
                />
                <div onClick={() => setSeePassword(!seePassword)}>
                  <VisibilityIcon className="eye-icon" />
                </div>
              </div>
            </div>
            <div className="login-component">
              <button className="login-btn">Entrar</button>
            </div>
            <p className="register-text">
              Não possui uma conta?{" "}
              <a href="/register" className="register-link">
                Cadastre-se
              </a>
            </p>
          </form>
        </div>
        <aside className="info-side" />
      </div>
      {alertText !== "" && (
        <AlertComponent
          alertText={alertText}
          setAlertText={setAlertText}
          severity={severity}
        />
      )}
    </section>
  );
};

export default LoginForm;
