import React, { useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import { useSearchParams } from "react-router-dom";

const AdminAssessmentCard = ({ data, setModalOpen }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [productsIsHovered, setProductsIsHovered] = useState(false);
  const [deleteIsHovered, setDeleteIsHovered] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  function returnBadgeProps(status) {
    if (status === 0) {
      return { description: "Pendente", color: "#ea9999" };
    }
    if (status === 1) {
      return { description: "Enviado", color: "#aec6db" };
    }
    if (status === 2) {
      return { description: "Aprovado", color: "#b6d7a8" };
    }
    if (status === 3) {
      return { description: "Rejeitado", color: "#ffe599" };
    }
  }

  const badgeProps = returnBadgeProps(data.status);

  return (
    <div className="admin-assessment-item">
      <div className="assessment-item-info">
        <h4 style={{ margin: 0 }}>{data.supplier}</h4>
        <p style={{ margin: 0 }}>{new Date(data.date).toLocaleDateString()}</p>
      </div>
      <div className="assessment-item-icons">
        <div
          style={{
            padding: 5,
            paddingLeft: 10,
            paddingRight: 10,
            backgroundColor: badgeProps?.color,
            borderRadius: 8,
            height: 20,
            // color: "white",
            fontWeight: 600,
            fontSize: 14,
            width: 65,
            textAlign: "center",
          }}
        >
          {badgeProps?.description}
        </div>
        <div>
          <CreateIcon />
          <button
            onClick={handleClick}
            style={{
              padding: 0,
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
            }}
          >
            <MoreVertIcon />
          </button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <div style={{ padding: 10 }}>
              <p
                onClick={() => {
                  setModalOpen();
                  handleClose();
                  setSearchParams({ id: data.id });
                }}
                onMouseEnter={() => setProductsIsHovered(true)}
                onMouseLeave={() => setProductsIsHovered(false)}
                style={{
                  cursor: "pointer",
                  padding: 10,
                  backgroundColor: productsIsHovered ? "#F0DEC1" : "",
                  margin: 0,
                  borderRadius: 5,
                  textAlign: "center",
                }}
              >
                Ver produtos
              </p>
              <div
                style={{
                  borderBottom: "1px solid gray",
                  width: "100%",
                  marginTop: 2,
                  marginBottom: 2,
                }}
              ></div>
              <p
                onClick={() => {
                  setModalOpen();
                  handleClose();
                }}
                onMouseEnter={() => setDeleteIsHovered(true)}
                onMouseLeave={() => setDeleteIsHovered(false)}
                style={{
                  cursor: "pointer",
                  padding: 10,
                  backgroundColor: deleteIsHovered ? "#F0DEC1" : "",
                  margin: 0,
                  borderRadius: 5,
                  textAlign: "center",
                }}
              >
                Excluir
              </p>
            </div>
          </Popover>
          {/* pencil icon
          three dots */}
        </div>
      </div>
    </div>
  );
};

export default AdminAssessmentCard;
