import React, { useState } from "react"
import ProductTable from "../../components/productTable/index.tsx"

import './home.css'

const Home = () => {
    // const [busca, setBusca] = useState("")

    return (<section className="home">
        <ProductTable />
    </section>)
}

export default Home