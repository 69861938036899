import React from "react";
import MailSent from "../../assets/mail-sent.svg";

import "./modalConfirmation.css";

interface PropsModalConfirmation {
  aoFechar: () => void;
  aoConfirmar: () => void;
}

const ModalConfirmation = ({
  aoFechar,
  aoConfirmar,
}: PropsModalConfirmation) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-title">
          <h4>Confirmar exclusão</h4>
          <button className="btn-close-modal" onClick={aoFechar}>
            <span className="close">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>Deseja excluir sua conta?</p>
          <p>Esta ação não poderá ser revertida.</p>
        </div>
        <div className="btn-confirmation-wrapper">
          <button className="btn-cancel-modal" onClick={aoFechar}>
            Cancelar
          </button>
          <button className="btn-confirm-modal" onClick={aoConfirmar}>
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmation;
