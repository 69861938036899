import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import Rotas from './routes/index.tsx';


import './App.css';

const queryClient = new QueryClient()

function App() {
  return (
    <div className="App">
      <main className='app-main'>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Rotas />
          </BrowserRouter>
        </QueryClientProvider>
      </main>
    </div>
  );
}

export default App;
