import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import AdminForm from "../../components/adminForm/index.tsx";
import AdminNavbar from "../../components/adminNavbar/index.tsx";

import "./Admin.css";

const Admin = () => {
  const token = sessionStorage.getItem("token") || "";
  const [header, payload, signature] = token.split(".");

  const userData = JSON.parse(atob(payload));

  if (userData.role == "USER") {
    return <></>;
  }

  return (
    <section className="admin-page">
      <AdminNavbar />
      <Outlet />
    </section>
  );
};

export default Admin;
