import React from "react"
import { Outlet } from "react-router-dom"
import Navbar from "../../components/navbar/index.tsx"
import './Base.css'

const Base = () => {
    return (<main>
        <Navbar />
        <section className="main">
            <Outlet />
        </section>
    </main>)
}

export default Base