import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./userbasicinfo.css";
import userIcon from "../../assets/user-icon.svg";
import { IUser } from "../../interfaces/IUser.ts";
import http from "../../http/index.tsx";
import utils from "../../utils/utils.tsx";
import AlertComponent from "../alertComponent/index.tsx";
import ModalConfirmation from "../modalConfirmation/index.tsx";

const UserBasicInfo = () => {
  const [file, setFile] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const inputFileElem = useRef<HTMLInputElement>(null);
  const [alertText, setAlertText] = useState("");
  const [severity, setSeverity] = useState("");
  const [modalOpened, setModalOpened] = useState(false);

  const token = sessionStorage.getItem("token") || "";
  const [header, payload, signature] = token.split(".");
  const userData = JSON.parse(atob(payload));

  let navigate = useNavigate();

  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [cpf, setCPF] = useState("");
  const [celular, setCelular] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    http
      .get<IUser[]>(`user/${userData.userID}`)
      .then((resposta) => {
        console.log(resposta.data[0]);
        const cpf = utils.cpfFormatter(resposta.data[0].cpf);
        const phoneNumber = utils.phoneNumberFormatter(
          resposta.data[0].celular.toString()
        );
        setNome(resposta.data[0].nome);
        setSobrenome(resposta.data[0].sobrenome);
        setCPF(cpf);
        setCelular(phoneNumber);
        setEmail(resposta.data[0].email);
        setFile(resposta.data[0].imagemPerfil);
      })
      .catch((erro) => console.log(erro));
  }, []);

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setFile(URL.createObjectURL(e.target.files[0]));
      setImage(e.target.files[0]);
    }
  };

  const deleteAccount = () => {
    http
      .delete(`/user/${userData.userID}`)
      .then(() => navigate("/"))
      .catch((error) => {
        if (error?.response?.data?.message) {
          setAlertText(error.response.data.message);
          setSeverity("error");
        } else {
          setAlertText(
            "Não foi possível excluir a conta, aguarde um momento e tente novamente."
          );
          setSeverity("error");
        }
      });
  };

  const submitForm = (evento: React.FormEvent<HTMLFormElement>) => {
    evento.preventDefault();
    const CPFNumbers = cpf.replace(/\D/g, "");
    const PhoneNumbers = celular.replace(/\D/g, "");
    const usuario = {
      nome,
      sobrenome,
      cpf: CPFNumbers,
      celular: PhoneNumbers,
      email,
    };

    http.put(`user/${userData.userID}`, usuario).then(() => {
      alert("Dados atualizados com sucesso!");
    });

    if (image) {
      try {
        http.post(
          `user/image/${userData.userID}`,
          { image },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  return (
    <section className="form-container">
      <h1 className="text-user">
        {" "}
        Bem-vinda! Aqui estão algumas de suas informações:
      </h1>
      <form className="userform" onSubmit={submitForm}>
        <figure className="user-image-container">
          {/* <img src={file} alt="icone-usuario" className="user-image"/> */}
          {file ? (
            <img src={file} alt="icone-usuario" className="user-image" />
          ) : (
            <img src={userIcon} alt="icone-usuario" className="user-icon" />
          )}
        </figure>
        <span
          className="add-user-image"
          onClick={() => inputFileElem.current?.click()}
        >
          Alterar imagem
        </span>
        <input
          type="file"
          accept=".png, .jpg, .jpeg"
          onChange={handleChange}
          style={{ display: "none" }}
          ref={inputFileElem}
        />
        <div className="components">
          <div className="info-component">
            <span>Nome:</span>
            <span>Sobrenome:</span>
            <span>CPF:</span>
            <span>Celular:</span>
            <span>Email:</span>
            <span>Senha:</span>
          </div>
          <div className="input-component">
            <input
              className="nome-info"
              type="text"
              value={nome}
              onChange={(event) => setNome(event.target.value)}
            />
            <input
              className="sobrenome-info"
              type="text"
              value={sobrenome}
              onChange={(event) => setSobrenome(event.target.value)}
            />
            <input
              className="cpf-info"
              type="text"
              readOnly
              value={cpf}
              onChange={(event) => setCPF(event.target.value)}
            />
            <input
              className="celular-info"
              type="text"
              value={celular}
              onChange={(event) => setCelular(event.target.value)}
            />
            <input
              className="email-info"
              type="text"
              readOnly
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <input
              className="senha-info"
              type="password"
              readOnly
              value={"password"}
            />
          </div>
        </div>
        <div className="container-button">
          <button className="button-update">Atualizar dados</button>
        </div>
        <div className="container-button">
          <button
            className="button-delete"
            type="button"
            onClick={() => setModalOpened(true)}
          >
            Excluir conta
          </button>
        </div>
      </form>
      {modalOpened && (
        <ModalConfirmation
          aoFechar={() => setModalOpened(false)}
          aoConfirmar={deleteAccount}
        />
      )}
      {alertText !== "" && (
        <AlertComponent
          alertText={alertText}
          setAlertText={setAlertText}
          severity={severity}
        />
      )}
    </section>
  );
};

export default UserBasicInfo;
