import React from 'react'
import UserBasicInfo from '../../components/userBasicInfo/index.tsx'

const UserProfile = () => {
    return (
    <section className="user-profile">
        <UserBasicInfo />
    </section>
    )
}

export default UserProfile;