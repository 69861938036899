import React, { useEffect } from 'react';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import './alertComponent.css'

const AlertComponent = ({ alertText, setAlertText, severity }) => {

    useEffect(() => {
        // Set timeout to hide the alert after 5 seconds
        const timeout = setTimeout(() => {
            setAlertText('');
        }, 5000);
    
        // Clear timeout when component unmounts to prevent memory leaks
        return () => clearTimeout(timeout);
    }, []);

    return(
        <div className='alert-component'>
            <Collapse in={(alertText !== '')}>
                <Alert
                severity={severity}
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setAlertText('');
                    }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                {alertText}
                </Alert>
            </Collapse>
        </div>
    )
}

export default AlertComponent;