import React, { useEffect, useState } from "react"
import './EmailConfirmation.css'
import http from "../../http/index.tsx"
import { useSearchParams } from "react-router-dom"
import EmailAuthenticated from "../../components/emailAuthenticated/index.tsx"
import EmailAuthFailed from "../../components/emailAuthFailed/index.tsx"
import Loader from "../../components/loader/index.tsx"

const EmailConfirmation = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get('token') || '';
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const sendToken = (data) => {
        setIsLoading(true);
        http.post('/user/email-confirmation', {confirmationToken: data})
            .then((data) => {
                setIsLoading(false);
                setIsAuthenticated(true);
            })
            .catch((data) => {
                setIsLoading(false);
                setIsAuthenticated(false);
            })
    }

    useEffect(() => {
        sendToken(token);
    }, [])
        
    return (<main>
        <section className="main">
        {isAuthenticated ? (
            <EmailAuthenticated />
        ) : (
            <div>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <EmailAuthFailed />
                    </>)
                }
            </div>
        )}
        </section>
    </main>)
}

export default EmailConfirmation