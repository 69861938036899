import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import HomeIcon from "@mui/icons-material/Home";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import logoSite from "../../assets/logo-site.png";

import "./adminNavbar.css";

const AdminNavbar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const path = window.location.pathname;

  const options = [
    {
      id: 0,
      title: "Início",
      icon: <HomeIcon />,
      url: "/admin/home",
    },
    {
      id: 1,
      title: "Curadoria",
      icon: <CheckroomIcon />,
      url: "/admin/assessment",
    },
  ];

  useEffect(() => {
    const current = options.find((item) => path.includes(item.url))?.id || 0;
    setSelectedItem(current);
  }, [path]);

  return (
    <div className="admin-nav-wrapper">
      <div className="admin-navbar">
        <div className="open-sidebar-icon" onClick={() => setSidebarOpen(true)}>
          <DensityMediumIcon />
        </div>
        <div className="icon-admin">
          <img src={logoSite} alt="" className="recriv-logo" />
        </div>
      </div>
      <div id="sidebar" className={sidebarOpen ? "sidebar active" : "sidebar"}>
        <button
          className="sidebar-close-btn"
          aria-label="Close alert"
          type="button"
          onClick={() => setSidebarOpen(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="sidebar-items-wrapper">
          {options.map((item) => (
            <Link
              className={selectedItem === item.id ? "active" : ""}
              to={item.url}
              key={item.id}
              onClick={() => setSelectedItem(item.id)}
            >
              {item.icon}
              {item.title}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminNavbar;
