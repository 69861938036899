import React, { useState, useEffect } from "react";
import WhatsLogo from '../../assets/wpp-logo.svg';
import http from "../../http/index.tsx";
import { IProduct } from '../../interfaces/IProduct.ts';
import ProductList from "../productList/index.tsx";
import Balance from './assets/balance-icon.svg'
import Money from './assets/money-icon.svg'

import './productTable.css'

const ProductTable = () => {

    const formatador = Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' });

    const [busca, setBusca] = useState('');
    const [prodJSON, setProdJSON] = useState<any[]>([]);
    const [products, setProducts] = useState<IProduct[]>([]);
    const [columnFilters, setColumnFilters] = useState<any[]>([]);
    
    const taskName = columnFilters.find(f => f.id === 'titulo')?.value || ''; 

    const onFilterChange = (id, value) => setColumnFilters(
        prev => prev.filter(f => f.id !== id).concat({
            id,value
        })
    )

    const token = sessionStorage.getItem('token') || '';
    const [header, payload, signature] = token.split('.');
    const userData = JSON.parse(atob(payload));

    const supplierKey = userData.supplierKey;

    useEffect(() => {
        http.get(`product/${supplierKey}`)
        .then(resposta => {
            setProducts(resposta.data);
        })
        .catch(erro => console.log(erro))
    }, []);

    const total = products.reduce((sum, product) => {
        return sum + product.preco;
    }, 0);

    const sold = products.reduce((sum, product) => {
        if (product.statusvenda === 1) {
            return sum + product.preco;
        }
        return sum;
    }, 0);

    const payed = products.reduce((sum, product) => {
        if ((product.statusvenda === 1) && (product.statuspagamento)) {
            return sum + product.preco;
        }
        return sum;
    }, 0);


    const totalComission = total * 0.40;
    const amountToReceive = (sold - payed) * 0.40;

    return(
        <section className="content">
            <div className="values">
                <div className="total">
                    <img src={Balance} className='balance-icon' alt="" />
                    <div className="total-wrapper">    
                        <h4 className="total-title">Total das peças</h4>
                        <h1 className="total-value">{formatador.format(totalComission)}</h1>
                    </div>
                </div>
                <div className="receive">
                    <img src={Money} className='money-icon' alt="" />
                    <div className="receive-wrapper">
                        <h4 className="receive-title">Valor a receber</h4>
                        <h1 className="receive-value">{formatador.format(amountToReceive)}</h1>
                    </div>
                    
                </div>
            </div>
            <ul className="campoBusca">
                <form className="buscar">
                    <input 
                        placeholder="Qual peça deseja encontrar?"
                        value={taskName}
                        onChange={(event) => onFilterChange('titulo', event.target.value.toUpperCase())}
                        className="input-busca"
                    />
                </form>
            </ul>
            <div className="product-table">
                <ProductList
                    products={products}
                    columnFilters={columnFilters}
                    setColumnFilters={setColumnFilters}
                />
            </div>
            <a href="https://wa.me/5549920027840" target="_blank">
                <img src={WhatsLogo} alt="Logo do WhatsApp" className="logo-whatsapp" />
            </a>
          </section>
    )
}

export default ProductTable;