import React from "react"
import RecrivLogo from '../../assets/logo-site.png'

import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
    return (
        <section className="policy-container">
            <div>
                <img className='logo-recriv' src={RecrivLogo} alt="Logo da Recriv" />
            </div>
            <h1>Política de Privacidade</h1>
            <div>
                <h3>1. Introdução</h3>
                <p>
                    Bem-vindo ao aplicativo de controle para fornecedoras da Recriv, o Recriv Fornecedoras. 
                    Esta Política de Privacidade descreve como coletamos, usamos, armazenamos e protegemos suas informações pessoais quando você utiliza nosso aplicativo. A Recriv compromete-se a proteger sua privacidade e assegurar que seus dados pessoais sejam tratados de acordo com a legislação aplicável.
                </p>
                <h3>2. Coleta de Dados</h3>
                <p>
                    Ao criar uma conta no Aplicativo, coletamos as seguintes informações pessoais:
                </p>
                <p>
                    - Nome completo
                </p>
                <p>
                    - Endereço de e-mail
                </p>
                <p>
                    - Número de telefone
                </p>
                <p>
                    - CPF
                </p>
                <h3>3. Uso dos Dados</h3>
                <p>
                    Os dados coletados serão utilizados exclusivamente para as seguintes finalidades:
                </p>
                <p>
                    - Gerenciamento de acesso ao aplicativo
                </p>
                <p>
                    - Consulta de informações relevantes às operações da Recriv e suas fornecedoras
                </p>
                <p>
                    - Comunicação entre a Recriv e suas fornecedoras
                </p>
                <h3>4. Armazenamento e Segurança dos Dados</h3>

                <p>
                    A Recriv implementa medidas de segurança técnicas e administrativas adequadas para proteger seus dados pessoais contra acesso não autorizado, 
                    perda, alteração ou destruição. Seus dados serão armazenados em servidores seguros e acessíveis apenas por pessoal autorizado da Recriv.
                </p>
                <h3>5. Compartilhamento de Dados</h3>
                <p>
                    Seus dados pessoais não serão compartilhados com terceiros, exceto quando necessário para cumprir obrigações legais ou regulatórias.
                </p>
                <h3>6. Direitos dos Usuários</h3>
                <p>
                    Você tem o direito de:
                </p>
                <p>
                    - Acessar seus dados pessoais armazenados pela Recriv
                </p>
                <p>
                    - Solicitar a correção de dados pessoais incorretos ou incompletos
                </p>
                <p>
                    - Solicitar a exclusão de seus dados pessoais
                </p>
                <p>
                    Para exercer esses direitos, entre em contato com nossa equipe de suporte através do e-mail recrivtech@gmail.com.
                </p>
                <h3>7. Exclusão de Conta</h3>
                <p>
                    Caso deseje excluir sua conta, todos os seus dados pessoais serão permanentemente removidos de nossa base de dados. Para solicitar a exclusão de sua conta, envie um e-mail para recrivtech@gmail.com com o assunto "Exclusão de Conta".
                </p>
                <h3>8. Alterações na Política de Privacidade</h3>
                <p>
                    A Recriv reserva-se o direito de alterar esta Política de Privacidade a qualquer momento. Notificaremos você sobre quaisquer alterações significativas por meio do aplicativo ou por e-mail. 
                    Recomendamos que você revise esta política periodicamente para se manter informado sobre nossas práticas de privacidade.
                </p>
                <h3>9. Contato</h3>
                <p>
                    Se você tiver alguma dúvida sobre esta Política de Privacidade ou sobre como tratamos seus dados pessoais, 
                    entre em contato conosco pelo e-mail recrivtech@gmail.com.
                </p>
                <h3>10. Legislação Aplicável</h3>
                <p>
                    Esta Política de Privacidade é regida e interpretada de acordo com as leis do Brasil, 
                    em especial a Lei Geral de Proteção de Dados Pessoais (LGPD), Lei nº 13.709/2018.
                </p>
                <p>
                    ---
                </p>
                <p>
                    Última atualização: 14 de junho de 2024.
                </p>
                <p>
                    ---
                </p>
                <p>
                    **Recriv**
                </p>
                <p>
                    Rua Duque de Caxias, 537, Centro - Joaçaba/SC
                </p>
                <p>
                    (49) 92002-7840
                </p>
                <p>
                    recrivtech@gmail.com
                </p>
                <p>
                    Esta Política de Privacidade foi elaborada para garantir a transparência e a segurança no tratamento de dados pessoais no âmbito do uso do aplicativo de controle para fornecedoras da Recriv. Ao utilizar o aplicativo, você concorda com os termos desta política.
                </p>
            </div>
        </section>
    )
}

export default PrivacyPolicy