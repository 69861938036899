import React from 'react';
import MailSent from '../../assets/mail-sent.svg'

import './modalEmail.css'

interface PropsModalEmail {
    aoFechar: () => void
}

const ModalEmail = ({ aoFechar } : PropsModalEmail) => {

    return (
        <div className="modal">
            <div className="modal-content">
                <div className='modal-title'>
                    <h4>Confirmar e-mail</h4>
                    <button className='btn-close-modal' onClick={aoFechar}>
                        <span className="close">&times;</span>
                    </button>
                </div>
                <div className='modal-body'>
                    <img className='mail-icon' src={MailSent} alt="" />
                    <p>Sua conta Recriv está quase pronta.</p>
                    <p>Para ativá-la, por favor verifique seu e-mail.</p>
                </div>
            </div>
        </div>)
}

export default ModalEmail;