import React from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import http from "../../http/index.tsx";

import "./adminModalAssessment.css";

const AdminModalAssessment = ({ setModalOpen }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  const formatador = Intl.NumberFormat("pt-br", {
    style: "currency",
    currency: "BRL",
  });

  const {
    data: products,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["getSupplierProducts"],
    queryFn: () => {
      const response = http
        .get(`/product/${9}`)
        .then((res) => {
          console.log(res.data);
          return res.data;
        })
        .catch((error) => console.log(error));

      return response;
    },
  });

  //   const { data: products, isLoading } = useQuery({
  //     queryKey: ["assessmentProducts"],
  //     queryFn: () => {
  //       //Retorna os produtos da curadoria
  //     },
  //   });

  return (
    <div
      style={{
        backgroundColor: "rgba(63, 63, 63, 0.553)",
        position: "absolute",
        width: "100vw",
        height: "100vh",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="modal-products-content">
        <button
          className="sidebar-close-btn"
          aria-label="Close alert"
          type="button"
          onClick={() => {
            setModalOpen(false);
            searchParams.delete("id");
            setSearchParams(searchParams);
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div
          style={{
            width: "100%",
            height: "90%",
            paddingLeft: "10%",
          }}
        >
          <h2>Produtos</h2>
          <ul
            style={{
              listStyle: "none",
              padding: 2,
              overflowY: "scroll",
              height: "75%",
            }}
          >
            {Array.isArray(products) &&
              products.map((item) => (
                <li
                  style={{
                    boxShadow: "0 0 4px black",
                    borderRadius: 5,
                    height: 40,
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 10,
                    paddingRight: 10,
                    width: "88%",
                    justifyContent: "space-between",
                    marginBottom: 12,
                    gap: 24,
                  }}
                >
                  <p>{item.titulo}</p>
                  <p>{formatador.format(item.preco)}</p>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminModalAssessment;
