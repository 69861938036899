import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IUser } from "../../interfaces/IUser.ts";
import http from "../../http/index.tsx";
import logoSite from "../../assets/logo-site.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import useComponentVisible from "./componentVisible.ts";

import "./navbar.css";
import utils from "../../utils/utils.tsx";

const Navbar = () => {
  const [user, setUser] = useState<IUser[]>([]);
  const [redirect, setRedirect] = useState("");
  const [admin, setAdmin] = useState(false);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible();

  let navigate = useNavigate();

  const handleLogout = () => {
    navigate("/");
    if (sessionStorage.getItem("token")) {
      sessionStorage.removeItem("token");
    }
  };

  const handleClickProfile = () => {
    navigate("/user/profile");
  };

  const token = sessionStorage.getItem("token") || "";
  const [header, payload, signature] = token.split(".");

  const userData = JSON.parse(atob(payload));

  const handleClick = () => {
    const isAdmin = utils.isAdminEmail(userData.email);
    if (isAdmin) {
      navigate("/admin/home");
    } else {
      navigate("/user/home");
    }
  };

  useEffect(() => {
    if (userData.email) {
      setAdmin(utils.isAdminEmail(userData.email));
    }
  }, []);

  return (
    <section className="navbar">
      <div onClick={handleClick} style={admin ? { marginLeft: "20px" } : {}}>
        <img src={logoSite} alt="Logo da Enjoy" className="logo-enjoy-nav" />
      </div>
      <div className="user-data">
        <div
          className="user"
          ref={ref}
          onClick={() => setIsComponentVisible(!isComponentVisible)}
        >
          <span className="user-name">
            Olá, <strong>{userData.nome}</strong>
          </span>
          {userData.imagemPerfil ? (
            <figure className="navbar-user-icon">
              <img
                src={userData.imagemPerfil}
                alt="Imagem de perfil"
                className="navbar-user-image"
                loading="lazy"
              />
            </figure>
          ) : (
            <AccountCircleIcon style={{ fontSize: "2.5rem" }} />
          )}
        </div>
        {isComponentVisible ? (
          <ul className="menu">
            <div className="menu-options">
              <li onClick={handleClickProfile}>Meus dados</li>
            </div>
            <li onClick={handleLogout}>Sair</li>
          </ul>
        ) : (
          <></>
        )}
      </div>
    </section>
  );
};

export default Navbar;
