import React from "react";
import AccessDenied from "../../assets/access-denied.svg";

import "./emailAuthFailed.css";

const EmailAuthFailed = () => {
  return (
    <div className="content-wrapper">
      <h1>Autenticação falhou.</h1>
      <h4>
        A confirmação do e-mail falhou, tente novamente ou entre em contato com
        nosso suporte.
      </h4>
      <div className="icon-wrapper">
        <img className="authenticated-icon" src={AccessDenied} alt="" />
      </div>
      <a className="redirect-btn" href="/">
        Voltar ao início
      </a>
    </div>
  );
};

export default EmailAuthFailed;
