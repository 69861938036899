import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import http from "../../http/index.tsx";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";

import "./NewAssessment.css";
import AddAssessmentProducts from "../../components/addAssessmentProducts/index.tsx";
import utils from "../../utils/utils.tsx";

interface AssessmentProducts {
  id: number;
  titulo: string;
}

const NewAssessment = () => {
  const navigate = useNavigate();
  const listRef = useRef(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [checkedProducts, setCheckedProducts] = useState<AssessmentProducts[]>(
    []
  );
  const [supplierEmpty, setSupplierEmpty] = useState(false);
  const [supplierCPF, setSupplierCPF] = useState("");
  const [supplierId, setSupplierId] = useState(0);
  const [supplierName, setSupplierName] = useState("");

  const emptySupplier = () => {
    setSupplierEmpty(true);
    setTimeout(() => {
      setSupplierEmpty(false);
    }, 5000);
  };

  const removeItem = (id) => {
    setCheckedProducts(checkedProducts.filter((prod) => prod.id !== id));
  };

  const addItem = ({ id, titulo }) => {
    if (!checkedProducts.find((item) => item.id == id)) {
      setCheckedProducts([...checkedProducts, { id, titulo }]);
    }
  };

  function verifyItem(id) {
    if (checkedProducts.find((item) => item.id === id)) {
      return true;
    }
    return false;
  }

  function formatTitle(title: string) {
    if (title.length > 26) {
      const substr = title.substring(0, 24) + "...";
      return substr;
    }
    return title;
  }

  const handleCPF = (value) => {
    if (value) {
      if (value.lenght > 14) {
        value = value.substring(0, 14);
      }
      const formattedCPF = utils.cpfFormatter(value);
      setSupplierCPF(formattedCPF);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <div className="new-assessment-container">
      <div className="form-title-wrapper">
        <button
          className="arrow-back-btn"
          onClick={() => navigate("/admin/assessment")}
        >
          <ArrowBackIcon />
        </button>
        <h1>Criar curadoria</h1>
      </div>
      <form className="new-assessment-form" onSubmit={handleSubmit}>
        <div className="assessment-input-wrapper">
          <label htmlFor="">Curadoria</label>
          <input type="text" />
        </div>
        <div className="assessment-input-wrapper">
          <label htmlFor="">CPF (Fornecedora)</label>
          <div style={{ position: "relative", width: "100%" }}>
            <input
              style={{ width: "100%", padding: 0 }}
              type="text"
              value={supplierCPF}
              onChange={(e) => {
                setSupplierCPF(e.target.value);
                if (e.target.value == "") {
                  setSupplierId(0);
                }
              }}
              onBlur={(e) => handleCPF(e.target.value)}
              className={supplierEmpty ? "missing-input" : ""}
            />
            <div
              className={
                "search-supplier-icon" + (supplierEmpty ? " missing-input" : "")
              }
              onClick={() => {
                setSupplierId(9);
                setSupplierName("Vinicius Telles dos Santos");
              }}
            >
              <SearchIcon />
            </div>
          </div>
          {supplierEmpty && (
            <p
              style={{
                margin: 0,
                marginTop: 0,
                color: "#ff0000",
                fontWeight: 500,
                fontSize: 15,
              }}
            >
              Associe uma fornecedora
            </p>
          )}
        </div>
        <div className="assessment-input-wrapper">
          <label htmlFor="">Fornecedora</label>
          <input type="text" readOnly={true} value={supplierName} />
        </div>
        <div className="assessment-grid-wrapper">
          <div className="assessment-input-wrapper">
            <label htmlFor="">Total de peças</label>
            <input type="text" />
          </div>
          <div className="assessment-input-wrapper">
            <label htmlFor="">Data de recebimento</label>
            <input type="date" />
          </div>
        </div>
        <div className="assessment-grid-wrapper">
          <div className="assessment-input-wrapper">
            <label htmlFor="">Peças recusadas</label>
            <input type="text" />
          </div>
          <div className="assessment-input-wrapper">
            <label htmlFor="">Data de retorno</label>
            <input type="date" placeholder="DD/MM/YYYY" />
          </div>
        </div>
        <div className="assessment-input-wrapper">
          <label htmlFor="">Peças aprovadas</label>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "white",
              padding: 20,
              border: "1px solid black",
              borderRadius: 5,
            }}
          >
            <div className="assessment-product-wrapper">
              <div
                className="add-assessment-product"
                onClick={() => {
                  supplierId > 0 ? setModalOpen(true) : emptySupplier();
                }}
              >
                Adicionar
              </div>
              {checkedProducts.map((product) => (
                <div className="assessment-product-item">
                  <div>{formatTitle(product.titulo)}</div>
                  <div
                    onClick={() => {
                      setCheckedProducts(
                        checkedProducts.filter((item) => item.id !== product.id)
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <DeleteIcon sx={{ color: "gray" }} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: 30,
            gap: 12,
          }}
        >
          <button
            style={{
              border: "0.1px solid black",
              boxShadow: "1px 1px 3px rgb(194, 194, 194)",
              height: "2.2rem",
              width: "8rem",
              backgroundColor: "rgb(239, 239, 239)",
              borderRadius: 5,
              cursor: "pointer",
              fontSize: 15,
              fontWeight: 600,
            }}
            type="button"
            onClick={() => navigate("/admin/assessment")}
          >
            Cancelar
          </button>
          <button
            style={{
              border: "0.1px solid black",
              boxShadow: "1px 1px 3px rgb(194, 194, 194)",
              height: "2.2rem",
              width: "8rem",
              backgroundColor: "#F0DEC1",
              borderRadius: 5,
              cursor: "pointer",
              fontSize: 15,
              fontWeight: 600,
            }}
          >
            Cadastrar
          </button>
        </div>
      </form>
      {modalOpen && (
        <AddAssessmentProducts
          setModalOpen={setModalOpen}
          supplierID={supplierId}
          removeFn={removeItem}
          addFn={addItem}
          checked={verifyItem}
        />
      )}
    </div>
  );
};

export default NewAssessment;
