import React from 'react';
import AuthenticatedIcon from '../../assets/authenticated-icon.svg'

import './emailAuthenticated.css'

const EmailAuthenticated = () => {

    return (
        <div className="content-wrapper">
            <h1>Bem-vinda!</h1>
            <h3>E-mail confirmado, realize seu login para continuar.</h3>
            <div className='icon-wrapper'>
                <img className='authenticated-icon' src={AuthenticatedIcon} alt="" />
            </div>
            <a className='redirect-btn' href='/'>Ir para o login</a>
        </div>)
}

export default EmailAuthenticated;