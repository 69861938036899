import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginForm from "../components/loginForm/index.tsx";
import RegisterForm from "../components/registerForm/index.tsx";
import Home from "../pages/Home/index.tsx";
import Base from "../pages/Base/index.tsx";
import UserProfile from "../pages/UserProfile/index.tsx";
import Admin from "../pages/Admin/index.tsx";
import EmailConfirmation from "../pages/EmailConfirmation/index.tsx";
import EmailAuthFailed from "../components/emailAuthFailed/index.tsx";
import PrivacyPolicy from "../pages/PrivacyPolicy/index.tsx";
import AdminForm from "../components/adminForm/index.tsx";
import AdminAssessment from "../pages/adminAssessment/index.tsx";
import NewAssessment from "../pages/NewAssessment/index.tsx";

const Rotas = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginForm />} />
      <Route path="/register" element={<RegisterForm />} />
      <Route path="/user" element={<Base />}>
        <Route path="/user/home" element={<Home />} />
        <Route path="/user/profile" element={<UserProfile />} />
      </Route>
      <Route path="/admin" element={<Admin />}>
        <Route path="/admin/home" element={<AdminForm />} />
        <Route path="/admin/assessment" element={<AdminAssessment />} />
        <Route path="/admin/assessment/new" element={<NewAssessment />} />
      </Route>
      <Route path="/email-confirmation" element={<EmailConfirmation />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  );
};

export default Rotas;
