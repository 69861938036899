import React, { useState, useEffect } from "react";
import logo from '../../assets/logo.png';
import VisibilityIcon from '@mui/icons-material/Visibility';
import http from "../../http/index.tsx";
import AlertComponent from '../alertComponent/index.tsx';
import utils from "../../utils/utils.tsx";
import ModalEmail from "../modalEmail/index.tsx";

import './registerForm.css'


const RegisterForm = () => {

    const [seePassword, setSeePassword] = useState(false);
    const [passwordType, setPasswordType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [severity, setSeverity] = useState('success');
    const [emailSent, setEmailSent] = useState(false);

    const [nome, setNome] = useState('');
    const [sobrenome, setSobrenome] = useState('');
    const [cpf, setCPF] = useState('');
    const [celular, setCelular] = useState('');
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [senhaConfirmada, setSenhaConfirmada] = useState('');

    const submitForm = (evento: React.FormEvent<HTMLFormElement>) => {
        evento.preventDefault();

        const isAdmin = utils.isAdminEmail(email);
        const role = isAdmin ? 'ADMIN' : 'USER';
        const numCPF = utils.onlyNumbers(cpf);
        const numPhone = utils.onlyNumbers(celular);

        const usuario = {
            nome,
            sobrenome,
            cpf: numCPF,
            celular: numPhone,
            email,
            senha,
            role
        };

        if (senha === senhaConfirmada) {
            http.post('user/register', usuario)
                .then(() => {
                    setAlertText('Usuário foi cadastrado com sucesso');
                    setSeverity('success');
                    setEmailSent(true);
                    setNome('');
                    setSobrenome('');
                    setCPF('');
                    setCelular('');
                    setEmail('');
                    setSenha('');
                    setSenhaConfirmada('');
                })
                .catch(error => {
                    console.log(error)
                    if (error.response) {
                        setAlertText(error.response.data.message[0]);
                        setSeverity('error');
                        // alert('Ops, algo deu errado. Código de status: ' + error.response.status);
                        console.log(error.response.data.message[0]);
                    } else if (error.request) {
                        alert('Ops, algo deu errado. Não houve resposta do servidor.');
                    } else {
                        alert('Ops, algo deu errado. Erro: ' + error.message);
                    }
                });
        }
        else {
            alert('Senhas não conferem, por favor, digite as senhas corretamente')
            setSenha('');
            setSenhaConfirmada('');
        }
    };

    const handlePhone = (value) => {
        const formattedNum = utils.phoneMask(value);
        setCelular(formattedNum);
    }

    const handleCPF = (value) => {
        if (value) {
            if (value.lenght > 14) {
                value = value.substring(0, 14)
            }
            const formattedCPF = utils.cpfFormatter(value);
            setCPF(formattedCPF);
        }
    }
    
    useEffect(() => {
        if (seePassword) {
            setPasswordType('text');
        } else {
            setPasswordType('password');
        }
    }, [seePassword]);

    return(
        <section className="wrapper">
            <form className='register' onSubmit={submitForm}>
                <a href="/" title="Logo"> 
                    <img src={logo} alt="Logo da Enjoy" className='logo-enjoy-register' />
                </a>
                <h1 className='welcome'>Cadastre sua conta</h1>
                <div className='register-component'>
                    <span>Nome</span>
                    <input className='name-input' type="text" value={nome} onChange={(event) => setNome(event.target.value)} />
                </div>

                <div className='register-component'>
                    <span>Sobrenome</span>
                    <input className='sobrenome-input' type="text" value={sobrenome} onChange={(event) => setSobrenome(event.target.value)} />
                </div>

                <div className='register-component'>
                    <span>CPF</span>
                    <input className='cpf-input' type="text" value={cpf} onChange={(event) => setCPF(event.target.value)} onBlur={(event) => handleCPF(event.target.value)} />
                </div>

                <div className='register-component'>
                    <span>Celular</span>
                    <input className='phone-input' type="text" value={celular} onChange={(event) => handlePhone(event.target.value)} />
                </div>

                <div className='register-component'>
                    <span>E-mail</span>
                    <input className='email-input' type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
                </div>

                <div className='register-component'>
                    <span>Senha</span>
                    <div className='senha'>
                        <input className='senha-input' type={passwordType} value={senha} onChange={(event) => setSenha(event.target.value)}/>
                        <div onClick={() => setSeePassword(!seePassword)}>
                            <VisibilityIcon className='eye-icon'/>
                        </div>
                    </div>
                </div>
                <div className='register-component'>
                    <span>Confirme sua senha</span>
                    <div className='senhaConfirmada-Input'>
                        <input className='senhaConfirmada-input' type="password" value={senhaConfirmada} onChange={(event) => setSenhaConfirmada(event.target.value)}/>
                    </div>
                </div>
                <div className="register-component">
                    <button className='register-btn' >
                        Cadastrar-se
                    </button>
                </div>
                <p className="register-text">Já possui uma conta? <a href="/" className="register-link">Realize o login</a></p>
            </form>
            {(alertText !== '') &&
                <AlertComponent
                    alertText={alertText}
                    setAlertText={setAlertText}
                    severity={severity}
                />
            }
            {(emailSent) && (
                <ModalEmail
                    aoFechar={() => setEmailSent(false)}
                />
            )}
        </section>
    )
}

export default RegisterForm;