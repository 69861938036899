import React, { useState } from "react";
import AdminAssessmentCard from "../../components/adminAssessmentCard/index.tsx";
import { Link, useSearchParams } from "react-router-dom";
import AdminModalAssessment from "../../components/adminModalAssessment/index.tsx";

import "./adminAssessment.css";

const data = [
  {
    date: "2024-07-26 00:00:00",
    assessments: [
      {
        id: 0,
        supplier: "Gilberto Sponchiado",
        date: "2024-07-26 00:00:00",
        status: 0,
      },
      {
        id: 1,
        supplier: "Vinicius Telles dos Santos",
        date: "2024-07-26 00:00:00",
        status: 0,
      },
    ],
  },
  {
    date: "2024-07-24 00:00:00",
    assessments: [
      {
        id: 2,
        supplier: "Joice Moraes",
        date: "2024-07-24 00:00:00",
        status: 3,
      },
      {
        id: 3,
        supplier: "Carol Pires",
        date: "2024-07-24 00:00:00",
        status: 1,
      },
    ],
  },
  {
    date: "2024-07-21 00:00:00",
    assessments: [
      {
        id: 4,
        supplier: "Jéssica Moraes",
        date: "2024-07-21 00:00:00",
        status: 1,
      },
      { id: 5, supplier: "Inês Silva", date: "2024-07-21 00:00:00", status: 1 },
      {
        id: 7,
        supplier: "Karina Vieira",
        date: "2024-07-21 00:00:00",
        status: 2,
      },
      {
        id: 6,
        supplier: "Amanda Silva",
        date: "2024-07-21 00:00:00",
        status: 0,
      },
    ],
  },
];

const options = {
  // weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

const AdminAssessment = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  return (
    <div className="admin-assessment">
      <div className="title-wrapper">
        <h1>Curadorias</h1>
        <a href="/admin/assessment/new" className="add-assessment">
          Adicionar
        </a>
      </div>
      <div style={{ overflowY: "scroll" }}>
        {data.map((assessment) => (
          <div className="admin-assessment-content">
            <h3 className="date-header" onClick={() => setModalOpen(true)}>
              {new Date(assessment.date).toLocaleDateString("pt-BR", options)}
            </h3>
            <div className="line"></div>
            {assessment.assessments.map((item) => (
              <div key={item.id}>
                <AdminAssessmentCard
                  data={item}
                  setModalOpen={() => setModalOpen(true)}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
      {modalOpen && id && <AdminModalAssessment setModalOpen={setModalOpen} />}
    </div>
  );
};

export default AdminAssessment;
