import React, { useState, useEffect, useRef } from "react";
import http from "../../http/index.tsx";
import { IProductAdmin } from "../../interfaces/IProduct.ts";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  PaginationState,
  getPaginationRowModel,
} from "@tanstack/react-table";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Popover from "@mui/material/Popover";
import SortIcon from "@mui/icons-material/Sort";
import { CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";

import "./adminForm.css";

const formatador = Intl.NumberFormat("pt-br", {
  style: "currency",
  currency: "BRL",
});

const AdminForm = () => {
  const columns = [
    {
      accessorKey: "titulo",
      header: "Produto",
      cell: (props) => <p>{props.getValue()}</p>,
      enableColumnFilter: true,
      enableSorting: true,
      filterFn: "includesString",
    },
    {
      accessorKey: "fornecedora",
      header: "Fornecedora",
      cell: (props) => <p>{props.getValue()}</p>,
    },
    {
      accessorKey: "preco",
      header: "Valor",
      cell: (props) => <p>{formatador.format(props.getValue())}</p>,
    },
    {
      accessorKey: "statusvenda",
      header: "Status",
      cell: (props) => (
        <div>
          {props.getValue() === 0 && (
            <p style={{ color: "green", fontWeight: "600" }}>Disponível</p>
          )}
          {props.getValue() === 1 && (
            <p style={{ color: "red", fontWeight: "600" }}>Vendido</p>
          )}
        </div>
      ),
    },
    {
      accessorKey: "datavenda",
      header: "Data da venda",
      cell: (props) => (
        <input
          type="date"
          value={props.getValue()}
          disabled={true}
          style={{ fontWeight: 550, backgroundColor: "transparent" }}
        />
      ),
      enableSorting: false,
      enableColumnFilter: true,
      filterFn: (row, columnId, filterStatuses) => {
        if (filterStatuses?.length === 0) return true;
        const date = row.getValue(columnId);
        return filterStatuses[0] <= date && filterStatuses[1] >= date;
      },
    },
    {
      accessorKey: "statuspagamento",
      header: "Pagamento",
      cell: (props) => (
        <div>
          {
            <select
              className="payment"
              defaultValue={getValue(props.getValue())}
              onChange={(e) =>
                paymentChange(
                  props.row.original.id,
                  e.target.options.selectedIndex,
                  0
                )
              }
              style={{ backgroundColor: "transparent" }}
            >
              <option value="false">Pendente</option>
              <option value="true">Pago</option>
            </select>
          }
        </div>
      ),
      enableSorting: false,
    },
    {
      accessorKey: "datapagamento",
      header: "Data do pagamento",
      cell: (props) => (
        <input
          type="date"
          value={props.getValue()}
          onChange={(e) =>
            paymentChange(props.row.original.id, e.target.value, 1)
          }
          disabled={props.row.original.statuspagamento !== 1}
          style={{ backgroundColor: "transparent" }}
        />
      ),
      enableSorting: false,
    },
  ];

  const [products, setProducts] = useState<IProductAdmin[]>([]);
  const [busca, setBusca] = useState("");
  const [columnFilters, setColumnFilters] = useState<any>([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const calendarRef = useRef<HTMLButtonElement>(null);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [searchFilter, setSearchFilter] = useState("");

  const getValue = (value) => {
    if (value === 0) {
      return "false";
    }
    if (value === 1) {
      return "true";
    }
  };

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //     setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(calendarRef.current);
  };

  const paymentChange = (id, value, type) => {
    let payment: {
      statuspagamento?: number;
      datapagamento?: Date;
    } = {};
    if (type === 0) {
      payment.statuspagamento = value;
    }
    if (type === 1) {
      payment.datapagamento = value;
    }
    console.log(id, value);
    http
      .put(`product/payment/${id}`, payment)
      .then(() => {})
      .catch((error) => {
        alert(error);
      });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const table = useReactTable({
    data: products,
    columns,
    state: {
      columnFilters,
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columnResizeMode: "onChange",
    onPaginationChange: setPagination,
  });

  const productName =
    columnFilters?.find((f) => f.id === searchFilter)?.value || "";

  const onFilterChange = (id, value) => {
    setColumnFilters((prev) =>
      prev
        .filter((f) => f.id !== id)
        .concat({
          id,
          value,
        })
    );
  };

  const filterDates =
    columnFilters?.find((f) => f.id === "datavenda")?.value || [];

  const onDateFilterClick = (id, start, end) => {
    try {
      setColumnFilters((prev) => {
        const statuses = prev?.find((filter) => filter.id === id)?.value;
        const isActive = filterDates.includes([start, end]);
        return prev?.concat({
          id,
          value: [start, end],
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    http
      .get(`product`)
      .then((resposta) => {
        setProducts(resposta.data.products);
      })
      .catch((erro) => console.log(erro));
  }, []);

  //   const onPaymentChange = (productId, newStatus) => {
  //     let isPayed = false;
  //     if (newStatus === "true") {
  //       isPayed = true;
  //     }
  //     setProducts(
  //       products.map((product) =>
  //         product.id === productId ? { ...product, pago: isPayed } : product
  //       )
  //     );
  //   };

  return (
    <section className="container-admin">
      <div className="filters">
        <ul className="busca">
          <form
            className="buscar"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <select
              className="filter-select"
              onChange={(e) => setSearchFilter(e.target.value)}
            >
              <option value="titulo">Produto</option>
              <option value="fornecedora">Fornecedora</option>
            </select>
            <input
              placeholder="Qual peça deseja encontrar?"
              value={productName}
              onChange={(event) =>
                onFilterChange(searchFilter, event.target.value.toUpperCase())
              }
              className="input-busca"
            />
          </form>
        </ul>
        <div className="btn-wrapper">
          <button
            aria-describedby={id}
            style={{ border: "none", padding: 10 }}
            onClick={handleClick}
            className="calendar"
            ref={calendarRef}
          >
            <CalendarMonthIcon color="action" />
          </button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transitionDuration={0}
          >
            <div className="date-range">
              <span>Data inicial</span>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <span>Data final</span>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              <button
                className="date-button"
                onClick={() =>
                  onDateFilterClick("datavenda", startDate, endDate)
                }
              >
                Filtrar
              </button>
            </div>
          </Popover>
          <hr
            style={{
              width: "1px",
              height: "20px",
              border: "none",
              background: "grey",
            }}
          />
          <CSVLink
            className="export-btn"
            filename="Produtos.csv"
            data={products}
          >
            Exportar
            <DownloadIcon />
          </CSVLink>
        </div>
      </div>
      <div className="card">
        <table className="table-data-admin" width={table.getTotalSize()}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th width={header.getSize()} key={header.id}>
                  {header.column.columnDef.header}
                  {header.column.getCanSort() && (
                    <div
                      className="sort-icon"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <SortIcon fontSize="small" sx={{ marginTop: 0.5 }} />
                    </div>
                  )}
                  <div
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                    className={`resizer ${
                      header.column.getIsResizing() ? "isResizing" : ""
                    }`}
                  ></div>
                </th>
              ))}
            </tr>
          ))}
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td width={cell.column.getSize()} key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </table>
      </div>
      <div style={{ display: "flex", gap: 5, marginTop: 15 }}>
        <button
          style={{
            padding: 15,
            backgroundColor: "white",
            border: "1px gray solid",
            borderRadius: 5,
            cursor: "pointer",
          }}
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {"<"}
        </button>
        <button
          style={{
            padding: 15,
            backgroundColor: "white",
            border: "1px gray solid",
            borderRadius: 5,
            cursor: "pointer",
          }}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {">"}
        </button>
      </div>
    </section>
  );
};

export default AdminForm;
