import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import http from "../../http/index.tsx";

import "./addAssessmentProduct.css";

const AddAssessmentProducts = ({
  setModalOpen,
  supplierID,
  removeFn,
  addFn,
  checked,
}) => {
  const skeletonArray = Array.from({ length: 6 });

  const formatador = Intl.NumberFormat("pt-br", {
    style: "currency",
    currency: "BRL",
  });

  const {
    data: products,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["getSupplierProducts"],
    queryFn: () => {
      const response = http
        .get(`/product/${supplierID}`)
        .then((res) => {
          return res.data;
        })
        .catch((error) => console.log(error));

      return response;
    },
  });

  useEffect(() => {
    refetch();
  }, [supplierID]);

  return (
    <div className="modal-background">
      <div className="modal-products-wrapper">
        <button
          className="sidebar-close-btn"
          aria-label="Close alert"
          type="button"
          onClick={() => {
            setModalOpen(false);
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div
          style={{
            width: "100%",
            height: "90%",
            paddingLeft: "10%",
          }}
        >
          <div style={{ marginBottom: "40px", width: "90%" }}>
            <h2 style={{ margin: 0 }}>Produtos</h2>
            <p style={{ marginTop: 6, color: "gray", fontWeight: 600 }}>
              Escolha os produtos que deseja inserir à curadoria
            </p>
          </div>
          <ul
            style={{
              listStyle: "none",
              overflowY: "scroll",
              height: "60%",
              width: "90%",
              display: "flex",
              flexDirection: "column",
              paddingTop: 10,
              paddingLeft: 0,
              paddingRight: 10,
              gap: "20px",
            }}
          >
            {Array.isArray(products) &&
              products.map((item) => (
                <li className="assessment-list-item" key={item.id}>
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 6 }}
                  >
                    <input
                      type="checkbox"
                      onClick={(e) => {
                        e.currentTarget.checked
                          ? addFn({ id: item.id, titulo: item.titulo })
                          : removeFn(item.id);
                      }}
                      defaultChecked={checked(item.id)}
                    />
                    <p>{item.titulo}</p>
                  </div>
                  <p>{formatador.format(item.preco)}</p>
                </li>
              ))}
            {isLoading &&
              skeletonArray.map((item) => (
                <li className="list-item-skeleton"></li>
              ))}
          </ul>
          <div className="modal-button-wrapper">
            <button
              className="cancel-button"
              onClick={() => setModalOpen(false)}
            >
              Cancelar
            </button>
            <button
              className="confirm-button"
              onClick={() => setModalOpen(false)}
            >
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAssessmentProducts;
